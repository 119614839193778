import type { FunctionComponent } from 'react'
import React, { useRef } from 'react'
import { useIntersection } from 'react-use'

import classnames from 'classnames'

import { PIHeaderSearch } from '../PIHeaderSearch'
import styles from './PISearchListHeader.module.scss'

export const PISearchListHeader: FunctionComponent<Props> = ({ children, ...props }) => {
  const intersectionRef = useRef(null)
  const intersection = useIntersection(intersectionRef, {
    threshold: 1,
  })

  return (
    <section
      ref={intersectionRef}
      data-testid="ea-search-list-header"
      className={classnames(
        styles.searchListHeaderWrapper,
        /* v8 ignore next */
        intersection && intersection.boundingClientRect.top < 0 && styles.isSticky
      )}
    >
      <div className={styles.searchListHeader}>{children}</div>
      <PIHeaderSearch {...props} />
    </section>
  )
}

type Props = { children: React.ReactNode } & Parameters<typeof PIHeaderSearch>[0]
